import { Link } from "react-router-dom";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
const SiteStaticCard = (objContentFull) => {
  if (objContentFull.fields == null) {
    return null;
  }

  const { tittle, link, textLink } = objContentFull.fields;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  const dataTemplate = {
    contentLoad: objContentFull.fields.contentLoad,
    templateLoad: objContentFull.fields.templateLoad,
  };

  let htmlObject = {
    html: "",
  };

  if (objContentFull.fields.description != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }

  return (
    <div key={objContentFull.sys.id} className="bg-white">
      <div className="px-8 pt-8 pb-3 lg:pb-8 lg:pt-14 lg:px-14 text-center">
        <h3 className="text-4xl text-gray-700 font-bold mb-2">{tittle}</h3>
        <p
          dangerouslySetInnerHTML={{ __html: htmlObject.html }}
          className="text-gray-600"
        ></p>
        {isExternalLink(link) ? (
          <a
            className="text-om_magenta hover:text-om_cyan underline flex items-center justify-center"
            href={link}
            target="_blank" // Opens link in a new tab for external links
            rel="noopener noreferrer" // Security measure for external links
          >
            {textLink}
          </a>
        ) : (
          <a
            className="text-om_magenta hover:text-om_cyan underline flex items-center justify-center"
            href={"/" + link}
          >
            {textLink}
          </a>
        )}
      </div>
      {objContentFull.fields.desktopImage && (
        <img src={objContentFull.fields.desktopImage.fields.file.url} alt="" />
      )}
    </div>
  );
};

const RenderFeatureSiteStaticCard = ({ card }) => {
  return (
    <div className="grid grid-cols-1 gap-1 lg:grid-cols-2 bg-slate-100 my-0.5">
      {card.dynamincComponent &&
        Array.isArray(card.dynamincComponent) &&
        card.dynamincComponent.map((objStaticCard) =>
          SiteStaticCard(objStaticCard)
        )}
    </div>
  );
};

export default RenderFeatureSiteStaticCard;
