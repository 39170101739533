import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commons/Layout/Header/Header";
import Footer from "../../commons/Layout/Footer";
const ContactUs = () => {
  const [objListCards, setListCards] = useState({});
  const [headerObject, setHeaderObject] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname.substring(1).toLowerCase();
  // Si currentPath está vacío, establece la ruta en "/home"
  if (currentPath === "") {
    currentPath = "home";
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "21673745",
          formId: "c0585b32-3479-46f4-ae9f-ccdac7addd95",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div>
      <Header listCards={headerObject} />
      <div className="mx-auto lg:mb-12 lg:pt-[125px] container px-0 lg:px-9">
        <div className="richText text-center">
          <h1>¡Hola!</h1>
          <h5>Nuestro equipo está comprometido a responder tus consultas lo más pronto posible. Apreciamos tu interés y estamos listos para asistirte.</h5>
        </div>
        <div id="hubspotForm" className="mt-16"></div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
