const SiteBannerSmall = ({ objContentFull }) => {
  if (objContentFull == null) {
    return null;
  }
  const { tittle, link, textLink, description } = objContentFull;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  const backgroundImageUrl = objContentFull.desktopImage
    ? `url('https:${objContentFull.desktopImage.fields.file.url}')`
    : { defaultValue: "" };

  return isExternalLink(link) ? (
    <a
      href={link}
      target="_blank" // Opens link in a new tab for external links
      rel="noopener noreferrer" // Security measure for external links
    >
      <div
        style={{ backgroundImage: backgroundImageUrl }}
        className="rounded-xl px-12 py-5 w-full bg-no-repeat bg-cover bg-center bg-opacity-90 bg-om_orange bg-blend-multiply mx-auto container my-2"
      >
        <div className="w-10/12 lg:w-4/12">
          <h3 className="text-3xl text-om_yellow leading-8">{tittle}</h3>
          <div className="leading-4 text-lg">{renderDescription(objContentFull)}</div>
        </div>
      </div>
    </a>
  ) : (
    <a href={`../${link}`}>
      <div
        style={{ backgroundImage: backgroundImageUrl }}
        className="rounded-xl px-12 py-5 w-full bg-no-repeat bg-cover bg-center bg-opacity-90 bg-om_orange bg-blend-multiply mx-auto container my-2"
      >
        <div className="w-10/12 lg:w-4/12">
          <h3 className="text-3xl text-om_yellow">{tittle}</h3>
          <div className="leading-4 text-lg">{renderDescription(objContentFull)}</div>
        </div>
      </div>
    </a>
  );
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.description != null) {
    objContentFull.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }

  return (
    <p className="text-white">
      <small>{htmlObject.html}</small>
    </p>
  );
};

const RenderSiteBannerSmall = ({ card }) => {
  return (
    <div>
      <SiteBannerSmall objContentFull={card} />;
    </div>
  );
};

export default RenderSiteBannerSmall;
