import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const SiteText = ({ objContentFull }) => {
  if (!objContentFull) {
    return null;
  }
  const { aditionalInfo = {}, description } = objContentFull;
  const { dynamicClass = {} } = aditionalInfo;
  const renderOptions = {
    renderNode: {
      "heading-1": (node, children) => {
        const h1Styles = {
          color: dynamicClass.h1?.color || "",
          width: dynamicClass.h1?.width || "",
          textAlign: dynamicClass.h1?.textalign,
        };
        return <h1 style={h1Styles}>{children}</h1>;
      },
      "heading-2": (node, children) => {
        const h2Styles = {
          color: dynamicClass.h2?.color || "",
          width: dynamicClass.h2?.width || "",
          textAlign: dynamicClass.h2?.textalign,
        };
        return <h2 style={h2Styles}>{children}</h2>;
      },
      "heading-3": (node, children) => {
        const h3Styles = {
          color: dynamicClass.h3?.color || "",
          width: dynamicClass.h3?.width || "",
          textAlign: dynamicClass.h3?.textalign,
        };
        return <h3 style={h3Styles}>{children}</h3>;
      },
      // Add more heading levels as needed
    },
  };

  return (
    <div className={`mx-auto container my-8 richText`} style={dynamicClass}>
      {documentToReactComponents(description, renderOptions)}
    </div>
  );
};

const RenderSiteText = ({ card }) => {
  return <SiteText objContentFull={card} />;
};

export default RenderSiteText;
