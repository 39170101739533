/* Icons */
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";

const SiteCategoryCards = (objContentFull) => {
  if (objContentFull.fields == null) {
    return null;
  }
  const backgroundImageUrl = objContentFull.fields.desktopImage
    ? "url('https:" + objContentFull.fields.desktopImage.fields.file.url + "')"
    : { defaultValue: "" };

  return (
    <div
      key={objContentFull.sys.id}
      style={{ backgroundImage: backgroundImageUrl }}
      className="h-[400px] max-w-[400px] min-w-[400px] p-8 rounded-lg  bg-no-repeat bg-cover bg-center whitespace-normal"
    >
        <h3 className="font-bold text-2xl"> {objContentFull.fields.tittle}</h3>
        {renderDescription(objContentFull)}
        <a
          className="text-black underline font-bold inline-block mt-2"
          href={`../${objContentFull.fields.link}`}
        >
          {objContentFull.fields.textLink}
        </a>
    </div>
  );
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.fields.description != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }

  return <p>{htmlObject.html}</p>;
};

const slideLeft = () => {
  var slider = document.getElementById("sliderHzCategory");
  slider.scrollLeft = slider.scrollLeft - 600;
};
const slideRight = () => {
  var slider = document.getElementById("sliderHzCategory");
  slider.scrollLeft = slider.scrollLeft + 600;
};

const RenderSiteCategoryCards = ({ card }) => {
  return (
    <div>
      <section className="mx-auto container mt-8">
        <div>
          <p className="text-slate-600 mb-2 w-5/12 text-xl">
            <span className="text-om_green font-bold">
              {card.attributeMain}
            </span>{" "}
            {card.attributeSecundary}
          </p>
        </div>

        <div
          id="sliderHzCategory"
          className="flex flex-row gap-5 p-6 pb-8 pl-0 w-full h-full overflow-x-scroll scroll-smooth whitespace-nowrap scrollbar-hide"
        >
          <div
            onClick={slideLeft}
            className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40 -ml-12"
          >
            <HiChevronLeft className="text-xxl w-5 h-5" />
          </div>
          <div
            onClick={slideRight}
            className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40 right-0 mr-8"
          >
            <HiChevronRight className="text-xxl w-5 h-5" />
          </div>
          {card.dynamincComponent &&
            Array.isArray(card.dynamincComponent) &&
            card.dynamincComponent.map((objCategoryCards) =>
              SiteCategoryCards(objCategoryCards)
            )}
        </div>
      </section>
    </div>
  );
};

export default RenderSiteCategoryCards;
