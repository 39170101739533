import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../../Button";
import { HiOutlineMenu} from "react-icons/hi";
import { getGenericComponent } from "../../../../contentful/apiContentFul";

const Menu = ({ children }) => {
  const dataTemplateOpencards = {
    contentLoad: "TemplateOpencards",
    templateLoad: "siteTemplateOpenCards",
  };

  const [listMenu, setListMenu] = useState({});

  const dataTemplateConsultoria = {
    contentLoad: "TemplateConsultoria",
    templateLoad: "siteTemplateConsultoria",
  };

  const dataTemplateKits = {
    contentLoad: "KitsOpenmind",
    templateLoad: "siteTemplateKits",
  };

  useEffect(() => {
    // Check if objListMenu is in localStorage
    const storedListMenu = localStorage.getItem("objListMenu");

    // objListMenu doesn't exist in localStorage, fetch and store it
    getGenericComponent("Menu")
    .then((res) => {
      localStorage.setItem("objListMenu", JSON.stringify(res));
      setListMenu({ ...setListMenu }); // Note: This line seems unnecessary, you might want to remove it
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

    /*if (!storedListMenu) {
      // objListMenu doesn't exist in localStorage, fetch and store it
      getGenericComponent("Menu")
        .then((res) => {
          console.log(res);
          localStorage.setItem("objListMenu", JSON.stringify(res));
          setListMenu({ ...setListMenu }); // Note: This line seems unnecessary, you might want to remove it
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      // objListMenu exists in localStorage, use it
      const parsedListMenu = JSON.parse(storedListMenu);
      setListMenu(parsedListMenu);
    }*/
  }, []);

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };
  
  const [isOpen, setIsOpen] = useState(false)
  const toggleNavbar = () => {
    setIsOpen(!isOpen);

  };

  return (
    <nav className="">
       <div className="hidden lg:flex flex-col lg:flex-row items-center lg:items-evenly gap-4 lg:gap-2 text-md md:text-sm lg:text-md text-slate-800 bg-white shadow-md lg:shadow-none p-5 lg:p-0 absolute lg:static z-20 top-0 left-0 mt-16 w-full lg:mt-0 lg:w-auto">
      <a
        href="/consultoria"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Consultoría
      </a>
      <a
        href="/disenoinnovacion"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Diseño & Innovación
      </a>
      <a
        href="/diagnosticoseguimiento"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Diagnóstico y Acompañamiento
      </a>
      <a
        href="/academy"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Academy
      </a>
      <a
        href="https://www.visualvoices.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Visual Voices
      </a>
      <a
        href="/homeopencards"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        OpenCards
      </a>
      <a
        href="https://www.yellowthinker.co"
        target="_blank"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Store
      </a>

      {listMenu[0]?.fields?.setting?.map((menuItem, index) => (
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
        >
          HOLA MUNDO
        </a>
      ))}
    </div>
    {/* Responsive */}
      <button onClick={toggleNavbar} className="lg:hidden rounded-full bg-gray-200 w-10 h-10 flex items-center justify-center hover:bg-gray-700 hover:text-gray-100 float-right"><HiOutlineMenu /></button>
    {isOpen && (
      <div className="flex lg:hidden flex-col lg:flex-row items-center lg:items-evenly gap-4 lg:gap-2 text-md md:text-sm lg:text-md text-gray-200 bg-gray-700 shadow-md lg:shadow-none p-5 lg:p-0 absolute lg:static z-20 top-0 left-0 mt-20 w-full lg:mt-0 lg:w-auto">
      <a
        href="/consultoria"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Consultoría
      </a>
      <a
        href="/disenoinnovacion"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Diseño & Innovación
      </a>
      <a
        href="/diagnosticoseguimiento"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Diagnóstico y Acompañamiento
      </a>
      <a
        href="/academy"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Academy
      </a>
      <a
        href="https://www.visualvoices.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Visual Voices
      </a>
      <a
        href="/homeopencards"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        OpenCards
      </a>
      <a
        href="https://www.yellowthinker.co"
        target="_blank"
        rel="noopener noreferrer"
        className="leading-5 text-center relative mx-3 hover:text-om_cyan lg:hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
      >
        Store
      </a>

      {listMenu[0]?.fields?.setting?.map((menuItem, index) => (
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="relative mx-3 hover:text-om_purple transition-all duration-75 ease-in hover:after:content-['•'] after:left-[50%] after:mt-0 hover:after:mt-3 after:absolute"
        >
          HOLA MUNDO
        </a>
      ))}
    </div>
    )} 
    {/* END Responsive */}
    </nav>
  );
};

export default Menu;
