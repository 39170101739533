/* Icons */
import {
  HiChevronRight,
  HiOutlineShoppingCart,
  HiChevronLeft,
} from "react-icons/hi2";

const SiteKitsMenu = (objContentFull) => {
  if (objContentFull.fields == null) {
    return null;
  }
  const imageUrl = objContentFull.fields.desktopImage
    ? "https:" + objContentFull.fields.desktopImage.fields.file.url
    : { defaultValue: "" };

  return (
    <a key={objContentFull.sys.id}  href={`../${objContentFull.fields.link}`} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
      <div
        key={objContentFull.sys.id}
        className="flex flex-col items-center w-[160px] whitespace-normal"
      >
        <div className="rounded-lg w-[141px] h-[211px] overflow-hidden">
          <img src={imageUrl} alt="" />
        </div>
        <p className="text-sm mt-2 text-center leading-tight text-slate-500">
          {objContentFull.fields.tittle}
        </p>
      </div>
    </a>
  );
};
const slideLeft = () => {
  var slider = document.getElementById("sliderHzKits");
  slider.scrollLeft = slider.scrollLeft - 600;
};
const slideRight = () => {
  var slider = document.getElementById("sliderHzKits");
  slider.scrollLeft = slider.scrollLeft + 600;
};

const RenderSiteKitsMenu = ({ card }) => {
  return (
    <div>
      <section className="mx-auto container px-8 lg:px-0 pt-8">
        <div className="flex flex-col lg:flex-row my-4">
          <div className="w-full lg:w-6/12">
            <h3 className="font-bold text-3xl">{card.attributeMain}</h3>
            <p className="text-slate-600 text-xl">{card.attributeSecundary}</p>
          </div>
          <div className="w-full lg:w-6/12 flex justify-start lg:justify-end gap-4 items-center mt-4 lg:mt-0">
            <div className="text-om_magenta border-2 p-3 border-om_magenta rounded-md">
              <HiOutlineShoppingCart className="text-3xl" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-om_magenta">Cómpralos en</span>
              <a className="underline inline-flex items-center -mt-1 hover:text-om_magenta" href="https://www.yellowthinker.co/" target="_blank">
                nuestra store <HiChevronRight />
              </a>
            </div>
          </div>
        </div>
        <div
          id="sliderHzKits"
          className="flex flex-row gap-5 p-6 pb-8 pl-0 w-full h-full overflow-x-scroll scroll-smooth whitespace-nowrap scrollbar-hide"
        >
          <div
            onClick={slideLeft}
            className="cursor-pointer bg-white p-3 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-20"
          >
            <HiChevronLeft className="text-xxl w-5 h-5" />
          </div>
          <div
            onClick={slideRight}
            className="cursor-pointer bg-white p-3 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-20 right-0 mr-8"
          >
            <HiChevronRight className="text-xxl w-5 h-5" />
          </div>
          {card.dynamincComponent &&
            Array.isArray(card.dynamincComponent) &&
            card.dynamincComponent.map((objKits) => SiteKitsMenu(objKits))}
        </div>
      </section>
    </div>
  );
};

export default RenderSiteKitsMenu;
