import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineCalendar, HiOutlineClock } from "react-icons/hi";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

const SiteEvenCard = ({ objEvenCard }) => {
  // const date = new Date(objContentFull.fields.fechaEvento);
  //const formattedDate = format(date, "MMMM d yyyy", { locale: es });
  //const capitalizedDate =
  // formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  /* */
  if (objEvenCard.fields == null) {
    return null;
  }

  const { tittle, link, textLink, description } = objEvenCard.fields;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };


  // Use aditionalInfo directly if it's an object
  const additionalInfo = objEvenCard.fields.aditionalInfo || {};

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-start">
        <div className="my-8 w-full h-[300] lg:h-[80vh] lg:overflow-hidden lg:w-6/12 flex border-l-[12px] lg:border-l-[28px] border-om_magenta">
          {objEvenCard.fields.desktopImage && (
            <img className="w-[auto]" src={objEvenCard.fields.desktopImage.fields.file.url} alt="" />
          )}
        </div>
        <div className="w-full text-slate-700 p-8 lg:w-6/12 lg:pl-14 text-left flex flex-col justify-center">
          {additionalInfo.tipoEvento && (
            <p className="text-slate-600 lg:text-lg">
              {additionalInfo.tipoEvento}
            </p>
          )}
          <h3 className="text-slate-700 text-xl font-bold leading-6 lg:text-4xl">
            {tittle}
          </h3>
          <p className="mx-auto container richText pr-12">
            {documentToReactComponents(description)}
          </p>
          <div className="my-3 flex gap-1 flex-col">
            {additionalInfo.fechaEvento && (
              <span className="flex flex-row gap-2 items-center font-bold">
                <HiOutlineCalendar className="text-3xl text-om_magenta" /> {additionalInfo.fechaEvento}
              </span>

            )}{" "}
            {additionalInfo.intensidad && (
              <span className="flex flex-row gap-2 items-center font-bold">
                <HiOutlineClock className="text-3xl text-om_magenta" />  {additionalInfo.intensidad}
              </span>
            )}
          </div>
          <div class="flex items-start justify-start">
           
          {objEvenCard.fields.textLink &&
            (isExternalLink(link) ? (
            <a
              className="bg-om_magenta p-3 rounded-lg text-white font-bold mt-4 hover:bg-slate-500"
              href={link}
              target="_blank" // Opens link in a new tab for external links
              rel="noopener noreferrer" // Security measure for external links
            >
              {textLink}
            </a>
          ) : (
            <a
              className="bg-om_magenta p-3 rounded-lg text-white font-bold mt-4 hover:bg-slate-500"
              href={"/" + link}
            >
              {textLink}
            </a>
          ))}



          </div>
        </div>
      </div>
    </>
  );
};

const RenderSiteEvenCard = ({ card }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="mb-0.5">
      <div className="bg-white flex flex-col min-h-min leading-none lg:flex-row lg:min-h-[30vh] lg:justify-center lg:items-center">
        <Carousel
          index={activeIndex}
          onChange={handleCarouselChange}
          animation="slide"
          autoPlay={false}
          disableSwipe={false}
          showArrows={true}
        >
          {card.dynamincComponent.map((eventCard) => (
            <SiteEvenCard
              key={`${eventCard.sys.id}-${Date.now()}`}
              objEvenCard={eventCard}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default RenderSiteEvenCard;
