// ModalContent.js
import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ModalContent = ({ showModal, closeModal, data }) => {
  if (!showModal) {
    return null;
  }

  const { aditionalDescription } = data;

  return (
    <>
      <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[60] outline-none focus:outline-none max-h-[95vh]">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}

            <div className="flex items-start justify-between p-6 mt-3 pb-0 rounded-t">
              <h3 className="text-3xl font-semibold">{data.tittle}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={closeModal}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 pt-0 flex-auto">
              <p
                className="my-4 text-blueGray-500 text-lg leading-relaxed"
              >
                   {documentToReactComponents(aditionalDescription)}
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-gray-500 hover:text-om_magenta background-transparent px-1 py-2 outline-none focus:outline-none mr-3 ease-linear transition-all duration-150"
                type="button"
                onClick={closeModal}
              >
                Cerrar
              </button>
              <a
                className="bg-om_magenta py-1 px-2 rounded-lg text-white font-bold hover:bg-slate-500"
                type="button"
                href="/contacto"
              >
                Contacto
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-50 bg-black"></div>
    </>
  );
};

export default ModalContent;
