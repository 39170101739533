import { client } from "./client";

export const getDynamicContent = async () => {
    try{
        return  client.getEntries(
        ).then(res => res.items);
    } catch(error){
        console.log(error);
    }
};


export const getGenericComponent = async (tittle) => {
    try{
        return  client.getEntries(Object.assign({
            content_type: "genericWebPage"
            //,locale : "es-CO"
            ,'fields.pathContent': tittle,
        }))
        .then(res => res.items);
    } catch(error){
        console.log(error);
    }
};

export const getComponentId = async (componentId) => {
    try{
        return  client.getEntries(Object.assign({
            content_type: "genericListContent"
            //,locale : "es-CO"
            ,'sys.id': componentId,
        }))
        .then(res => res.items);
    } catch(error){
        console.log(error);
    }
};
