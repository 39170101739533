import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../commons/Layout/Header/Header";
import Footer from "../../commons/Layout/Footer";
const Appointment = () => {
  const [headerObject, setHeaderObject] = useState({});
  return (
    <div>
      <Header listCards={headerObject} />
      <>
        <iframe
          title="Calendly Meeting"
          width="100%"
          height="800"
          src="https://calendly.com/franciscopenmind/reunion-informativa-openmind"
          frameBorder="0"
        ></iframe>
      </>
      <Footer />
    </div>
  );
};

export default Appointment;
