import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import FactoryWebPage from '../views/FactoryWebPage';
import ContactUs from '../views/ContactUs';
import Appointment from '../views/Appointment';


const RoutesComponent = () => {
    const location = useLocation();
  
    // Check if the current location matches any of the root paths and is not a subpath of "/academy"
    //const isRootPath = /^\/(disenoinnovacion|pensamientodiseno)$/.test(location.pathname);
    const isNotMindsetSubpath = !/^\/mindset\//.test(location.pathname);
  
    return (
      <Routes>
        {/* Render FactoryWebPage only if the path matches a root path and is not a subpath of "/academy" */}
        <Route path="*" element={<FactoryWebPage />} />}
        <Route path="/contacto" element={<ContactUs />} />
        <Route path="/agendatucita" element={<Appointment />} />
      </Routes>
    );
  }
  
  export default RoutesComponent;