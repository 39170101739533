import { useState } from "react";

import {
  HiOutlineBars3BottomRight,
  HiOutlineMagnifyingGlass,
  HiXMark,
} from "react-icons/hi2";

const Submenu = ({ objSubMenu }) => {
  if (Object.keys(objSubMenu).length > 0) {
    // Function to check if the link is external
    const isExternalLink = (link) => {
      return link && link.startsWith("http");
    };

    return (
      <div className="relative w-full z-0 overflow-x-scroll">
        <div className="flex items-start justify-start lg:justify-center px-4 w-full bg-gray-100 hover:bg-gray-50 lg:px-2 gap-2 lg:gap-10 text-xs lg:text-sm text-center">
          {objSubMenu[0].fields.dynamincComponent.map((item, index) => (
            <a
              key={item.fields.tittle}
              className="flex flex-col items-center justify-center pt-2 pb-1 px-2 border-gray-50/0 hover:border-om_purple border-b-4"
              href={isExternalLink(item.fields.textLink) ? item.fields.textLink : `../${item.fields.textLink}`} // Conditionally render href based on whether it's an external link or not
              target={isExternalLink(item.fields.textLink) ? "_blank" : null} // Set target="_blank" if it's an external link
              rel={isExternalLink(item.fields.textLink) ? "noopener noreferrer" : null} // Set rel="noopener noreferrer" if it's an external link
            >
              {item.fields.desktopImage && (
                <img className="my-1 w-8 opacity-70" src={item.fields.desktopImage.fields.file.url} alt="" />
              )}
              <span className="text-sm mt-1">{item.fields.tittle}</span>
            </a>
          ))}
        </div>
      </div>
    );
  }
};

export default Submenu;
