const SiteBannerContact = ({ objContentFull }) => {
  if (objContentFull == null) {
    return null;
  }
  const { title, link, textLink } = objContentFull;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  const backgroundImageUrl = objContentFull.desktopImage
    ? `url('https:${objContentFull.desktopImage.fields.file.url}')`
    : { defaultValue: "" };

  return (
    <div
      style={{ backgroundImage: backgroundImageUrl }}
      className="w-full h-[400px] lg:h-[75vh] bg-no-repeat bg-cover bg-center bg-opacity-90 bg-black"
    >
      <div className="w-full h-full flex flex-col gap-2 justify-center items-center bg-slate-700/80">
        <h3 className="lg:w-7/12 lg:max-w-[650px] text-4xl text-white leading-1 text-center">
          {title}
        </h3>
        {isExternalLink(link) ? (
          <a
            href={link}
            target="_blank" // Opens link in a new tab for external links
            rel="noopener noreferrer" // Security measure for external links
            className="inline-block bg-om_cyan text-white rounded-sm bold text-center py-2 px-6 mt-1 hover:bg-om_magenta transition ease-in-out duration-300 text-xl"
          >
            {textLink}
          </a>
        ) : (
          <a
            href={`../${link}`}
            className="inline-block bg-om_cyan text-white rounded-sm bold text-center py-2 px-6 mt-1 hover:bg-om_magenta transition ease-in-out duration-300 text-xl"
          >
            {textLink}
          </a>
        )}
      </div>
    </div>
  );
};

const RenderFeatureBannerContact = ({ card }) => {
  return <SiteBannerContact objContentFull={card} />;
};

export default RenderFeatureBannerContact;
