import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const SiteSliderCards = ({ objSliderCard }) => {
  if (objSliderCard.fields == null) {
    return null;
  }
  return (
    <>
      <div
        className="flex flex-col lg:flex-row justify-start"
      >
        <div className="w-full h-full flex flex-row gap-2 justify-center items-center py-8 pl-8">
          <div className="lg:w-6/12 lg:pl-14">
            <h3 className="flex flex-row items-center gap-3 text-4xl text-white">
              <span className="leading-1"> {objSliderCard.fields.tittle}</span>
            </h3>
            <p className="text-white text-md lg:text-xl lg:max-w-lg lg:mt-2">
              Descripcion
            </p>
            <a
              className="text-white underline font-bold mt-2 inline-block"
              href="http://"
            >
              Link del texto
            </a>
          </div>
          <div className="lg:w-6/12">
            <img src="https://picsum.photos/791/329" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

const RenderSiteSliderCards = ({ card }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="w-full h-[400px] lg:h-[85vh] bg-[url('https://picsum.photos/600/700')] bg-no-repeat bg-cover bg-center lg:bg-[url('https://picsum.photos/1500/1000')] bg-opacity-80 bg-slate-70">
      <Carousel
        index={activeIndex}
        onChange={handleCarouselChange}
        animation="slide"
        autoPlay={false}
        disableSwipe={false}
        showArrows={true}
      >
        {card.dynamincComponent.map((sliderCard) => (
          <SiteSliderCards  key={`${sliderCard.sys.id}-${Date.now()}`} objSliderCard={sliderCard} />
        ))}
      </Carousel>
    </div>
  );
};
export default RenderSiteSliderCards;
