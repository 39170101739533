import React, { useEffect } from 'react';

const HubSpotEmbed = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/21673745.js';

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up function
    return () => {
      // Remove the script from the document head when the component unmounts
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return null; // Since this component only deals with script loading, it doesn't render anything
};

export default HubSpotEmbed;