import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { format } from "date-fns";
import { es } from "date-fns/locale";

/* Icons */
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
  
const SiteInsightCards = (objContentFull) => {
  if (objContentFull.fields == null) {
    return null;
  }

  const date = new Date(objContentFull.sys.createdAt);
  const formattedDate = format(date, "MMMM d yyyy", { locale: es });
  const capitalizedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  const { tittle, link, textLink, description } = objContentFull.fields;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  return (
    <div
      key={objContentFull.sys.id}
      className="flex flex-col gap-6 shadow-2xl p-6 rounded-md"
    >
      <div className="rounded-md overflow-hidden object-cover h-[200px]">
        {objContentFull.fields.desktopImage && (
          <img
            className="w-full"
            src={objContentFull.fields.desktopImage.fields.file.url}
            alt=""
          />
        )}
      </div>
      <div className="whitespace-normal w-[350px]">
        <p className="text-sm text-slate-400">{capitalizedDate}</p>
        <h3 className="text-xl font-bold leading-none text-slate-600">
          {tittle}
        </h3>
        {renderDescription(objContentFull)}

        {objContentFull.fields.textLink &&
          (isExternalLink(link) ? (
            <a
              className="text-om_cyan hover:text-om_magenta underline font-bold flex flex-row gap-1 items-center"
              href={link}
              target="_blank" // Opens link in a new tab for external links
              rel="noopener noreferrer" // Security measure for external links
            >
              {textLink} <HiOutlineArrowNarrowRight />{" "}
            </a>
          ) : (
            <a
              className="text-om_cyan hover:text-om_magenta underline font-bold flex flex-row gap-1 items-center"
              href={"/" + link}
            >
              {textLink} <HiOutlineArrowNarrowRight />{" "}
            </a>
          ))}
      </div>
    </div>
  );
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.fields.description != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }
  return <p className="text-slate-500 my-2">{htmlObject.html}</p>;
};

const RenderSiteInsightCards = ({ card }) => {
  const sliderRef = useRef(null);

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 600;
    }
  };
  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 600;
    }
  };
  return (
    <section className="w-full mt-2">
      <div className="text-center">
        <h3 className="text-3xl bold text-slate-600">{card.attributeMain}</h3>
        <p className="text-sm text-om_magenta">{card.attributeSecundary}</p>
      </div>
      <div
        ref={sliderRef}
        className="flex flex-row gap-5 p-8 pb-20 w-full h-full overflow-x-scroll scroll-smooth whitespace-nowrap scrollbar-hide"
      >
        <div
          onClick={slideLeft}
          className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40"
        >
          <HiChevronLeft className="text-xxl w-5 h-5" />
        </div>
        <div
          onClick={slideRight}
          className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40 right-0 mr-8"
        >
          <HiChevronRight className="text-xxl w-5 h-5" />
        </div>
        {card.dynamincComponent &&
          Array.isArray(card.dynamincComponent) &&
          card.dynamincComponent.map((objInsightCard) =>
            SiteInsightCards(objInsightCard)
          )}
      </div>
    </section>
  );
};

export default RenderSiteInsightCards;
