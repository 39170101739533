import React, { useState, useRef } from "react";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
import ModalContent from "../../commons/Modal/Modalcontent.js"; // Assuming you have a separate ModalContent component

const SLIDER_STEP = 600; // Constant for slider step

const SiteLongCards = ({ objContentFull, openModal }) => {
  if (!objContentFull.fields) {
    return null;
  }

  const { desktopImage, tittle, description } = objContentFull.fields;
  const backgroundImageUrl = desktopImage
    ? `url('https:${desktopImage.fields.file.url}')`
    : "";

  return (
    <div
      onClick={() => openModal(objContentFull.fields)}
      key={objContentFull.sys.id}
      className="flex flex-col shadow-lg"
    >
      <div className="bg-white p-8 pb-0 mb-8 rounded-t-lg w-[350px] whitespace-normal h-[242px] overflow-hidden cursor-pointer" title="Haz clic sobre la tarjeta para ver toda la información">
        {tittle && <h3 className="font-bold text-2xl mb-3">{tittle}</h3>}
        {renderDescription(description)}
      </div>
      <div
        style={{ backgroundImage: backgroundImageUrl }}
        className="overflow-hidden rounded-b-lg h-[300px] w-[350px] bg-no-repeat bg-cover bg-center"
      ></div>
    </div>
  );
};

const renderDescription = (description) => {
  if (!description) {
    return null;
  }

  const html = description.content[0].content
    .map((obj) =>
      obj.marks[0] !== undefined ? `<b>${obj.value}</b>` : obj.value
    )
    .join("");

  return <p dangerouslySetInnerHTML={{ __html: html }} />;
};

const RenderSiteLongCards = ({ card }) => {
  const sliderRef = useRef(null);

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 600;
    }
  };
  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 600;
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const slide = (direction) => {
    const slider = document.getElementById("sliderHzLong");
    slider.scrollLeft += direction * SLIDER_STEP;
  };
 

  return (
    <div>
      <section className="mx-auto container mt-8">
        <div
          ref={sliderRef}
          className="flex flex-row gap-5 p-8 pl-0 w-full h-full overflow-x-scroll scroll-smooth whitespace-nowrap"
        >
          <div
            onClick={slideLeft}
            className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40 -ml-12"
          >
            <HiChevronLeft className="text-xxl w-5 h-5" />
          </div>
          <div
            onClick={slideRight}
            className="cursor-pointer bg-white p-5 opacity-60 hover:opacity-100 rounded-full shadow-lg absolute mt-40 right-0 mr-8"
          >
            <HiChevronRight className="text-xxl w-5 h-5" />
          </div>
          {/* ... (existing code) */}
          {card.dynamincComponent &&
            Array.isArray(card.dynamincComponent) &&
            card.dynamincComponent.map((objCardImage, index) => (
              <div key={index}>
                <SiteLongCards
                  objContentFull={objCardImage}
                  openModal={openModal}
                />
              </div>
            ))}
        </div>
      </section>

      <ModalContent
        showModal={showModal}
        closeModal={closeModal}
        data={modalData}
      />

      {/* Additional content, if any */}
    </div>
  );
};

export default RenderSiteLongCards;
