import {useState} from 'react';
import openmindLogo from "../../../assets/openmind.png";

import Menu from "../Header/Components/Menu";
import Submenu from "../Header/Submenu";
import SearchBox from "../Header/Components/SearchBox";
import HubSpotEmbed from '../Header/Components/HubSpotEmbed';
import GoogleTagManager from '../Header/Components/GoogleTagManager';

const Header = ({listCards,onHandleChangeSearch}) => {
    function handleMenu() {
        setMenuState( menuState => !menuState);
    }
    const [menuState, setMenuState] = useState(false);
    let toogleMenu = menuState ? 'opacity-100' : 'hidden';

    function handleSearch() {
        setSearchState( searchState => !searchState);
    }
    const [searchState, setSearchState] = useState(false);


    let toogleSearch = searchState ? 'hidden' : '';
    return (
        <header className='relative w-full lg:fixed z-50'>
            <HubSpotEmbed />
            <GoogleTagManager />
            <div className="bg-gray-700 leading-4 w-full p-1 text-gray-100 text-center font-medium">
            Obtén más información con nuestros consultores expertos. &nbsp;
                
                    <a
                    className="bg-om_cyan hover:bg-om_magenta rounded-md py-1 px-2 inline-block cursor-pointer"
                    href='agendatucita'
                    rel="noopener noreferrer" // Security measure for external links
                    >
                    ¡Agenda tu cita!
                    </a>
            </div>
            <div className="flex items-center justify-between lg:justify-center px-4 py-2 w-full bg-white lg:px-16 lg:py-1 lg:shadow-sm gap-20">
            <div>
               <a href="/"> <img className='min-w-[170px] max-w-[210px] ' src={openmindLogo} alt="" /></a>
            </div>
           
            
            <Menu/>  
            
            <div className='hidden'>
                <div className={`${toogleSearch}`}>
                    <SearchBox onHandleChangeSearch={onHandleChangeSearch}/>    
                </div>   
            </div>
            </div>   
            <Submenu className="mt-32" objSubMenu={listCards} /> 
                
        </header>
    );
}

export default Header;