import { Link } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const SiteListContent = (objContentFull) => {
  if (objContentFull == null) {
    return null;
  }
  const { aditionalInfo = {}, description } = objContentFull;
  const { dynamicClass = {} } = aditionalInfo;
  console.log(objContentFull);
  const renderOptions = {
    renderNode: {
      "heading-1": (node, children) => {
        const h1Styles = {
          color: dynamicClass.h1?.color || "",
          width: dynamicClass.h1?.width || "",
        };
        return <h1 style={h1Styles}>{children}</h1>;
      },
      "heading-2": (node, children) => {
        const h2Styles = {
          color: dynamicClass.h2?.color || "",
          width: dynamicClass.h2?.width || "",
        };
        return <h2 style={h2Styles}>{children}</h2>;
      },
      "heading-3": (node, children) => {
        const h3Styles = {
          color: dynamicClass.h3?.color || "",
          width: dynamicClass.h3?.width || "",
        };
        return <h3 style={h3Styles}>{children}</h3>;
      },
      // Add more heading levels as needed
    },
  };

  return (
    <div className="flex gap-5 my-5 pr-16">
      <div className="w-[70px] flex items-center my-5">
        {objContentFull.desktopImage && (
          <img className="w-full" src={objContentFull.desktopImage.fields.file.url} alt="" />
        )}
      </div>
      <div className="w-[100px] h-[100px] my-5 flex items-center justify-center">
        {objContentFull.desktopImage2 && (
          <img className="w-full" src={objContentFull.desktopImage2.fields.file.url} alt="" />
        )}
      </div>
      <div className="richText flex flex-col my-5 items-start justify-center">
        <h3>{objContentFull.tittle}</h3>
        <span>{documentToReactComponents(description, renderOptions)}</span>
      </div>
    </div>
  );
};

const RenderSiteListContent = (objContentFull) => {
  return (
    <div className="flex flex-col gap-5">
      {SiteListContent(objContentFull.card)}
    </div>
  );
};

export default RenderSiteListContent;
