import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SiteInformativeCardsw = ({ objInformativeCard }) => {
  if (objInformativeCard.fields == null) {
    return null;
  }
  
  return (
    <>
      <div
       
        className="flex flex-col lg:flex-row"
      >
        <div className="p-8 lg:w-6/12 items-center flex flex-col justify-center">
          {objInformativeCard.fields.desktopImage2 && (
            <img
              className="w-7/12 max-w-[200px]"
              src={objInformativeCard.fields.desktopImage2.fields.file.url}
              alt=""
            />
          )}
          <p className="my-3 text-white font-bold text-xl">
            {objInformativeCard.fields.tittle}
          </p>
          {renderDescription(objInformativeCard)}
          <a
            className="text-om_cyan hover:text-om_magenta underline font-bold"
            href={"/" + objInformativeCard.fields.link}
          >
            {objInformativeCard.fields.textLink}
          </a>
        </div>
        <div className="lg:w-6/12 overflow-hidden rounded-tl-[90px] border-l-8 border-white">
          {objInformativeCard.fields.desktopImage && (
            <img
              src={objInformativeCard.fields.desktopImage.fields.file.url}
              alt=""
            />
          )}
        </div>
      </div>
    </>
  );
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.fields.description != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }
  return <p className="my-3 text-white">{htmlObject.html}</p>;
};

const RenderSiteInformativeCard = ({ card }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="flex flex-col justify-between bg-gray-600 lg:flex-row ">
      <Carousel
        index={activeIndex}
        onChange={handleCarouselChange}
        animation="slide"
        autoPlay={false}
        disableSwipe={false}
        showArrows={true}
        showThumbs={false}
      >
        {card.dynamincComponent.map((informativeCard) => (
          <SiteInformativeCardsw key={`${informativeCard.sys.id}-${Date.now()}`} objInformativeCard={informativeCard} />
        ))}
      </Carousel>
    </div>
  );
};

export default RenderSiteInformativeCard;
