
const SiteCardImage = (objContentFull) => {
  if (objContentFull.fields == null) {
    return null;
  }
  return (
    <div
      key={objContentFull.sys.id}
      className="flex flex-row rounded-lg bg-om_cyan my-2 bg-opacity-30 py-6 px-10 justify-center items-center max-w-[600px]"
    >
      <div className="w-7/12">
        <h3 className="font-bold text-2xl leading-7">
          {objContentFull.fields.tittle}
        </h3>
        {renderDescription(objContentFull)}
        <a
          className="text-black underline font-bold inline-block mt-2 transition hover:text-om_orange duration-300"
          href={`../${objContentFull.fields.link}`}
        >
          {objContentFull.fields.textLink}{" "}
        </a>
      </div>
      <div className="w-5/12 flex justify-end">
        <img src="" className="w-20 h-20"/>
      </div>
    </div>
  );
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.fieldsdescription != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }

  return <p>{htmlObject.html}</p>;
};

const RenderSiteCardImage = ({ card }) => {
  return (
    <div>
      {card.dynamincComponent &&
        Array.isArray(card.dynamincComponent) &&
        card.dynamincComponent.map((objCardImage) =>
          SiteCardImage(objCardImage)
        )}
    </div>
  );
};

export default RenderSiteCardImage;
