import Button, { ButtonOwnerState, ButtonProps } from "@mui/material/Button";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useNavigate } from 'react-router-dom';

const SiteBannerHome = (objContentFull) => {
  const navigate = useNavigate();
  if (objContentFull.fields == null) {
    return null;
  }

  const { tittle, link, textLink, description } = objContentFull.fields;


  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  const backgroundImageUrl = objContentFull.fields.desktopImage
    ? `url('https:${objContentFull.fields.desktopImage.fields.file.url}')`
    : { defaultValue: "" };


  const className = `w-full flex items-center h-[400px] lg:h-[90vh] p-8 bg-no-repeat bg-cover mb-0.5 ${link ? 'cursor-pointer' : ''
    }`;

  const handleClick = (url) => {
    if (!url) return null; // Check if the link exists
    if (isExternalLink(url)) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      navigate(url); // Navigate to the specified URL
    }
  };

  return (
    <div onClick={() => handleClick(link)}
      key={objContentFull.sys.id}
      className={className}
      style={{ backgroundImage: backgroundImageUrl }}
    >
      <div className="lg:mt-8 w-full lg:w-5/12 lg:pl-16">
        <p className="text-black font-bold text-lg lg:text-4xl lg:max-w-2xl">{tittle}</p>
        <p className="mb-2 richText">
          {documentToReactComponents(description)}
        </p>
        {objContentFull.fields.textLink &&
          (isExternalLink(link) ? (
            <a
              className="transition-all inline-block bg-om_cyan text-white rounded-lg font-bold text-center py-1 px-4 mt-3 hover:bg-om_magenta shadow-lg hover:shadow-sm"
              href={link}
              target="_blank" // Opens link in a new tab for external links
              rel="noopener noreferrer" // Security measure for external links
            >
              {textLink}
            </a>
          ) : (
            <a
              className="transition-all inline-block bg-om_cyan text-white rounded-lg font-bold text-center py-1 px-4 mt-3 hover:bg-om_magenta shadow-lg hover:shadow-sm"
              href={`../${link}`}
            >
              {textLink}
            </a>
          ))}
      </div>
    </div>
  );
};

const RenderFeaturedBanner = ({ card }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  }
  return (
    <div>
      <Carousel
        index={activeIndex}
        onChange={handleCarouselChange}
        animation="slide"
        autoPlay={false}
        disableSwipe={false}
        showArrows={true}
      >
        {card.dynamincComponent &&
          Array.isArray(card.dynamincComponent) &&
          card.dynamincComponent.map((objBanner) => SiteBannerHome(objBanner))}
      </Carousel>
    </div>
  );
};

export default RenderFeaturedBanner;
