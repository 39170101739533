const SiteLeftInnerBanner = (objContentFull) => {
  if (objContentFull.fields != null) {
    const backgroundImageUrl = objContentFull.fields.desktopImage
      ? `url('https:${objContentFull.fields.desktopImage.fields.file.url}')`
      : { defaultValue: "" };

    const { tittle, link, textLink } = objContentFull.fields;

    // Function to check if the link is external
    const isExternalLink = (link) => {
      return link && link.startsWith("http");
    };

    return (
      <div
        key={objContentFull.sys.id}
        style={{ backgroundImage: backgroundImageUrl }}
        className="w-full h-[400px] lg:h-[85vh]  bg-no-repeat bg-cover bg-center  bg-opacity-80 bg-slate-700 bg-blend-multiply"
      >
        <div className="w-full h-full flex flex-col gap-2 justify-center items-start p-8">
          <div className="lg:w-6/12 lg:pl-14">
            <h3 className="flex flex-row items-center gap-3 text-4xl text-white">
              <span className="leading-1"> {tittle}</span>
            </h3>
            {renderDescription(objContentFull)}
            {isExternalLink(link) ? (
              <a
                className="text-om_cyan hover:text-om_magenta underline font-bold mt-2 inline-block text-lg"
                href={link}
                target="_blank" // Opens link in a new tab for external links
                rel="noopener noreferrer" // Security measure for external links
              >
                {" "}
                {textLink}{" "}
              </a>
            ) : (
              <a
                className="text-om_cyan hover:text-om_magenta underline font-bold mt-2 inline-block text-lg"
                href={`../${link}`}
              >
                {" "}
                {textLink}{" "}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const renderDescription = (objContentFull) => {
  let htmlObject = {
    html: "",
  };

  if (objContentFull.fields.description != null) {
    objContentFull.fields.description.content[0].content.forEach((obj) => {
      if (obj.marks[0] !== undefined) {
        htmlObject.html += `<b>${obj.value}</b>`;
      } else {
        htmlObject.html += `${obj.value}`;
      }
    });
  }

  return (
    <p className="text-white text-md lg:text-xl lg:max-w-lg lg:mt-2">
      {htmlObject.html}
    </p>
  );
};

const RenderSiteLeftInnerBanner = ({ card }) => {
  return (
    <div>
      {card.dynamincComponent &&
        Array.isArray(card.dynamincComponent) &&
        card.dynamincComponent.map((objCardImage) =>
          SiteLeftInnerBanner(objCardImage)
        )}
    </div>
  );
};

export default RenderSiteLeftInnerBanner;
