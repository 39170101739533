import React from "react";

const SiteFeaturedMessage = (objContentFull) => {
  const { title, link, textLink } = objContentFull;

  // Function to check if the link is external
  const isExternalLink = (link) => {
    return link && link.startsWith("http");
  };

  return (
    <div className="bg-gray-700 leading-4 w-full p-4 text-gray-100 lg:text-center font-medium">
      {title}{" "}
      {isExternalLink(link) ? (
        <a
          className="text-om_cyan hover:text-om_magenta underline"
          href={link}
          target="_blank" // Opens link in a new tab for external links
          rel="noopener noreferrer" // Security measure for external links
        >
          {textLink}
        </a>
      ) : (
        <a
          className="text-om_magenta underline"
          href={`../${link}`} // Internal link
        >
          {textLink}
        </a>
      )}
    </div>
  );
};

const RenderFeaturedMessage = (objContentFull) => {
  return <SiteFeaturedMessage {...objContentFull} />;
};

export default RenderFeaturedMessage;
