import openmindLogo from "../../assets/openmind.png";
import openmindLogo2 from "../../assets/thinkwithoutborders.png";
import { RiInstagramFill, RiYoutubeFill, RiLinkedinBoxFill, RiFacebookFill } from "react-icons/ri";
const Footer = () => {
    return (
        <footer className="bg-gray-100 flex flex-col gap-10 lg:gap-2 pb-16 pt-10 lg:pt-20 text-sm">
            <div className="container mx-auto flex flex-col lg:flex-row">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-5 w-full lg:w-9/12 py-4 lg:py-2 pl-8 pr-8 lg:pr-10">
                       {/*  <ul>
                            <li className="text-om_cyan bg-gray-500 p-3 rounded">Sabemos que a través de la conexión, el diseño, el empoderamiento y la capacidad transformadora de los seres humanos, cambia el mundo.</li>
                        </ul> */}
                        <ul>
                            <li><a className="font-bold">Nosotros</a></li>
                            <li><a href="/propositosvalores" className="hover:underline">Propósitos y Valores</a></li>
                            <li><a href="/tecnologiasmetodologiashumanas" className="hover:underline">Tecnologías Humanas</a></li>
                            
                        </ul>
                        <ul>
                            <li><a className="font-bold">Soluciones</a></li>
                            <li><a href="/consultoria" className="hover:underline">Consultoría</a></li>
                            <li><a href="/disenoinnovacion" className="hover:underline">Diseño & Innovación</a></li>
                            <li><a href="/diagnosticoseguimiento" className="hover:underline">Diagnóstico y Acompañamiento</a></li>
                        </ul>
                        <ul className="">
                            <li><a href="/insights" className="font-bold hover:underline">Insights</a></li>
                            <li className="lg:text-xs">Conoce más de nuestro enfoque innovador, encuentra artículos, conceptos, noticias, metodologías y los últimos desarrollos creados por Openmind.</li>
                        </ul>
                        
                        <ul>
                            <li><a href="/homeopencards" className="font-bold hover:underline">OpenCards</a></li>
                            <li><a href="/metodo" className="hover:underline">Método</a></li>
                            <li><a href="/implementacion" className="hover:underline">Implementación</a></li>
                            <li><a href="/kitsopencards#" className="hover:underline">Kits OpenCards</a></li>
                            <li><a href="/academy" className="hover:underline">Fórmate</a></li>
                        </ul>
                        <ul>
                            <li><a href="/academy" className="font-bold hover:underline">Academy</a></li>
                            <li><a href="/academy" className="hover:underline">Formación</a></li>
                            <li><a href="https://openmind-academy.com/Campus/" target="_blank" className="hover:underline">Campus</a></li>
                            
                        </ul>
                        <ul>
                            <li><a href="https://www.visualvoices.co/" target="_blank" className="font-bold hover:underline">Visual Voices</a></li>
                            <li><div>
                                <p className="lg:text-xs">Empodera a equipos remotos, presenciales o híbridos, para que revolucionen sus reuniones y trabajen eficientemente.</p>
                                <a href="https://visualvoices.co/" target="_blank" className="text-om_cyan hover:text-om_magenta font-bold underline">¡Empieza gratis!</a>
                                </div></li>
                        </ul>
                </div>
                <div className="w-full lg:w-3/12 border-none lg:border-l border-om_magenta/50 pl-8 lg:pl-10 py-4">
                    <ul className="flex flex-col gap-2">
                        <li><a className="font-bold">Store</a>
                        <p><a href="https://www.yellowthinker.co/" target="_blank" className="text-om_cyan hover:text-om_magenta font-bold underline">Adquiere nuestros kits aquí!</a></p></li>
                        <li><a href="/contacto" className="font-bold hover:underline">Contacto</a></li>
                    </ul>
                    <ul className="mt-2 mb-2">
                        <li><a className="font-bold">Oficinas:</a></li>
                    </ul>
                    <div className="flex flex-col gap-4 px-2 py-0 rounded">
                        <ul>
                            <li>Bogotá, Colombia</li>
                            
                            <li className="ml-6 list-disc">Móvil: (57) 3163126432</li>
                        </ul>
                        <ul>
                            <li>Santiago, Chile</li>
                            <li className="ml-6 list-disc">Móvil: (569 +) 62366357</li>
                        </ul>
                        <ul>
                            <li>La Paz, Bolivia</li>
                            <li className="ml-6 list-disc">Móvil: (591 +) 71540656</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex lg:block flex-col items-center mb-6 lg:mb-0">
                    <h3 className="text-om_magenta text-xl mb-2 lg:mb-0">Únete a nuestra comunidad</h3>
                    <div className="flex flex-row gap-5 items-center">
                        <a title="LinkedIn" href="https://www.linkedin.com/company/1522464/" target="_blank" className="text-3xl opacity-60 hover:opacity-100"><RiLinkedinBoxFill/></a>
                        <a title="Instagram" href="https://www.instagram.com/openmind.cd" target="_blank" className="text-3xl opacity-60 hover:opacity-100"><RiInstagramFill/></a>
                        <a title="YouTube" href="https://www.youtube.com/channel/UCLrDggir0DKS467KDQietaQ" target="_blank" className="text-3xl opacity-60 hover:opacity-100"><RiYoutubeFill/></a>
                        <a title="Facebook" href="https://www.facebook.com/Openmind.CD/" target="_blank" className="text-3xl opacity-60 hover:opacity-100"><RiFacebookFill/></a>
                        <div className="hidden"><input type="text"></input></div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row">
                <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row gap-4 items-center text-sm mt-4">
                        <a href="/terminosycondiciones" className="hover:underline">Términos y Condiciones</a>
                        <span className="hidden lg:block">|</span>
                        <a href="/politicasdeprivacidad" className="hover:underline">Políticas de Privacidad</a>
                        
                    </div>
                    <span className="hidden lg:block mt-3 text-center lg:text-left text-gray-400">Openmind Consulting & Design © 2024 Todos los derechos reservados</span>
                </div>
                    
                    
                    <div className="w-full lg:w-6/12 flex flex-col lg:flex-row items-center justify-end gap-6 mt-6 lg:mt-0">
                    <img className="max-w-[250px] mt-3 lg:mt-0" src={openmindLogo} alt="" />
                        <img className="max-w-[150px]" src={openmindLogo2} alt="" />
                        
                        <span className="lg:hidden mt-3 text-center lg:text-left text-gray-400">Openmind Consulting & Design © 2024 Todos los derechos reservados</span>
                    </div>
                    
                </div>
                
            </div>
            
        </footer>
    );
}

export default Footer;