import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../commons/Layout/Header/Header";
import Footer from "../../commons/Layout/Footer";
import RenderFeaturedMessage from "../ComponentsContent/SiteFeaturedMessage";
import RenderFeaturedBanner from "../ComponentsContent/SiteBannerHome";
import RenderFeatureSiteStaticCard from "../ComponentsContent/SiteStaticCard";
import RenderSiteBannerSmall from "../ComponentsContent/SiteBannerSmall";
import RenderSiteCardImage from "../ComponentsContent/SiteCardImage";
import RenderSiteKitsMenu from "../ComponentsContent/SiteKitsMenu";
import RenderSiteInformativeCard from "../ComponentsContent/SiteInformativeCards";
import RenderSiteInsightCards from "../ComponentsContent/SiteInsightCards";
import RenderFeatureBannerContact from "../ComponentsContent/SiteBannerContact";
import RenderSiteCategoryCards from "../ComponentsContent/SiteCategoryCards";
import RenderSiteLongCards from "../ComponentsContent/SiteLongCards";
import RenderSiteEvenCard from "../ComponentsContent/SiteEventCard";
import RenderSiteLeftInnerBanner from "../ComponentsContent/SiteLeftInnerBanner";
import RenderSiteSliderCards from "../ComponentsContent/SiteSliderCards";
import RenderSiteText from "../ComponentsContent/SiteText";
import RenderSiteListContent from "../ComponentsContent/SiteListContent";
import {
  getGenericComponent,
  getComponentId,
} from "../../contentful/apiContentFul";

const FactoryWebPage = () => {
  const [objListCards, setListCards] = useState({});
  const [headerObject, setHeaderObject] = useState({});

  const { pathname, hostname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname.substring(1).toLowerCase();
  const domain = window.location.hostname;

  const domainMappings = {
    "opencards.co": "homeopencards",
    "openmind-academy.com": "academy"
    // Add more mappings as needed
  };
  
  // Set currentPath to "homeopencard" when the path is "https://opencards.co/"
  if (domain === "opencards.co" && currentPath === '') {
    currentPath = domainMappings[domain];
  } else if (domain === "openmind-academy.com" && currentPath === '')  {
    currentPath = domainMappings[domain];
  } 
  else if (currentPath === '') {
    currentPath = 'home';
  }

  useEffect(() => {
    // Check if data is in localStorage
    const storedData = localStorage.getItem(currentPath);

    getGenericComponent(currentPath).then((res) => {
      localStorage.setItem(currentPath, JSON.stringify(res[0].fields));
      setListCards({ ...res[0].fields });
    });
    if (storedData) {
      setListCards(JSON.parse(storedData));
    } else {
      getGenericComponent(currentPath).then((res) => {
        localStorage.setItem(currentPath, JSON.stringify(res[0].fields));
        setListCards({ ...res[0].fields });
      });
    }
    
  }, [currentPath]);

  useEffect(() => {
    if (Object.keys(objListCards).length > 0) {
      const objSubMenu = objListCards.dynamicContent.find(
        (item) => item.fields.typeContent === "SubMenu"
      );
      if (objSubMenu) {
        getComponentId(objSubMenu.sys.id).then((res) => {
          setHeaderObject(res);
        });
      }
    }
  }, [objListCards]);

  return (
    <div>
      <Header listCards={headerObject} />
      <div className="lg:pt-[180px]">
        <RenderContent listCards={objListCards} />
      </div>
      <Footer />
    </div>
  );
};

const RenderContent = ({ listCards }) => {
  if (Object.keys(listCards).length > 0) {
    return (
      <div>
        <Helmet>
          <title>{listCards.tittlePage}</title>
          <meta name="description" content={listCards.descriptionPage} />
        </Helmet>
        {listCards.dynamicContent.map((objCard) => (
          <div key={objCard.sys.id}>
            <RenderCardByType card={objCard} />
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const RenderCardByType = ({ card }) => {
  // Declare state variables unconditionally
  const [componentData, setComponentData] = useState(null);

  // Effect to fetch component data if needed
  useEffect(() => {
    // Array of types that require calling getComponentId before rendering
    const typesRequireId = [
      "SiteInformativeCards",
      "SiteBanner",
      "SiteStaticCard",
      "SiteInsightCards",
      "SiteKitsMenu",
      "SiteCardImage",
      "SiteCategoryCards",
      "SiteCategoryCards",
      "SiteLongCards",
      "SiteEventCard",
      "SiteLeftInnerBanner",
      "SiteSliderCards"
    ];

    // Check if the current card's type requires calling getComponentId
    if (typesRequireId.includes(card.fields.typeContent)) {
      // Check if data is in localStorage
      const storedData = localStorage.getItem(card.sys.id);
      getComponentId(card.sys.id).then((res) => {
        localStorage.setItem(card.sys.id, JSON.stringify(res[0].fields));
        // Store the result in state
        setComponentData(res[0].fields);
      });
      if (storedData) {
        setComponentData(JSON.parse(storedData));
      } else {
        getComponentId(card.sys.id).then((res) => {
          localStorage.setItem(card.sys.id, JSON.stringify(res[0].fields));
          // Store the result in state
          setComponentData(res[0].fields);
        });
      }
    }
  }, [card]);

  // Render the appropriate component based on the card's type
  switch (card.fields.typeContent) {
    case "SiteInformativeCards":
      return componentData ? (
        <RenderSiteInformativeCard card={componentData} />
      ) : null;
    case "SiteBanner":
      return componentData ? (
        <RenderFeaturedBanner card={componentData} />
      ) : null;
    case "SiteStaticCard":
      return componentData ? (
        <RenderFeatureSiteStaticCard card={componentData} />
      ) : null;
    case "SiteInsightCards":
      return componentData ? (
        <RenderSiteInsightCards card={componentData} />
      ) : null;

    case "SiteKitsMenu":
      return componentData ? <RenderSiteKitsMenu card={componentData} /> : null;

    case "SiteCardImage":
      return componentData ? (
        <RenderSiteCardImage card={componentData} />
      ) : null;

    case "SiteCategoryCards":
      return componentData ? (
        <RenderSiteCategoryCards card={componentData} />
      ) : null;

    case "SiteLongCards":
      return componentData ? (
        <RenderSiteLongCards card={componentData} />
      ) : null;

    case "SiteEventCard":
      return componentData ? <RenderSiteEvenCard card={componentData} /> : null;

    case "SiteLeftInnerBanner":
      return componentData ? (
        <RenderSiteLeftInnerBanner card={componentData} />
      ) : null;
    case "SiteSliderCards":
      return componentData ? (
        <RenderSiteSliderCards card={componentData} />
      ) : null;

    //Type static
    case "SiteFeaturedMessage":
      return RenderFeaturedMessage(card.fields);
    case "SiteBannerContact":
      return <RenderFeatureBannerContact  card={card.fields} />;
    case "SiteBannerSmall":
      return <RenderSiteBannerSmall card={card.fields} />;
    case "SiteText":
      return <RenderSiteText card={card.fields} />;
    case "SiteListContent":
      return <RenderSiteListContent card={card.fields} />;
    default:
      return null;
  }
};


export default FactoryWebPage;